<template>

  <div>
    <b-card
      no-body
      class="p-2 mb-1"
    >
      <b-row>
        <b-col
          v-for="(item,index) in botones"
          :key="index"
          xl="2"
          lg="2"
          md="3"
          sm="6"
          xs="12"
        >
          <b-button
            size="md"
            :variant="btnActivo === item.codigo ? resolveEstadoVariant(item.codigo) : 'flat-secondary'"

            block
            @click="filtrarConvocatorias(item.codigo)"
          >
            <feather-icon :icon="item.icon" />
            {{ item.nombre }}
          </b-button>
        </b-col>
        <b-col
          xl="2"
          lg="2"
          md="3"
          sm="6"
          xs="12"
        >
          <b-dropdown
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :text="textHistorial"
            :variant="btnActivo === 'concluido' || btnActivo === 'cancelado' ? 'danger' : 'flat-secondary'"
            block
            size="md"
          >
            <b-dropdown-item @click="filtrarConvocatorias('concluido')">
              Concluidos
            </b-dropdown-item>
            <b-dropdown-item @click="filtrarConvocatorias('cancelado')">
              Cancelados
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      no-body
      class="mb-0 px-2"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                size="md"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        id="my-table"
        ref="refOpbTablaConvocatorias"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :no-provider-filtering="true"
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :height="myProvider.length < 5 ? '200px' : null"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <!-- Column: servicios -->
        <template #cell(servicios)="data">
          <div
            v-for="xx in data.item.serviciosx"
            :key="xx.codigo"
            class="text-nowrap"
          >
            <feather-icon
              :icon="resolveServicioIcon(xx.codigo)"
              size="15"
              class="mr-50"
              :class="`text-${resolveServicioVariant(xx.codigo)}`"
            />
            <span
              class="align-text-top text-capitalize"
              :class="`text-${resolveServicioVariant(xx.codigo)}`"
            >{{ xx.nombre }} ({{ xx.n_becas }})</span>
          </div>
        </template>

        <!-- Column: comunicados -->
        <template #cell(comunicados)="data">
          <b-button
            v-for=" (val,index) in data.item.comunicados"
            :key="index"
            :href="val.archivo"
            target="_blank"
            size="sm"
            variant="outline-danger"
            block
          >
            <feather-icon
              icon="FileTextIcon"
              size="10"
            />
            <span style="font-size:9px">
              {{ val.nombre }}
            </span>
            <br>
            <span style="font-size:8px">
              {{ val.created_at }}
            </span>
          </b-button>
        </template>

        <!-- Nombre -->
        <template #cell(nombre)="data">
          <span class="text-primary"><strong>{{ data.item.nombre }}</strong></span>
        </template>

        <!-- Column: estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ data.item.estado }}
          </b-badge>
        </template>

        <!-- Incripcion -->
        <template #cell(inscripcion)="data">
          <span class="text-primary"><strong>Inicio: </strong></span>{{ data.item.fecha_inicio }} <br>
          <span class="text-primary"><strong>Fin: </strong></span>{{ data.item.fecha_fin }} <br>
        </template>

        <!-- Column: estado -->
        <template #cell(accion)="data">

          <b-button
            variant="outline-success"
            size="sm"
            block
            @click="$router.push({ name: 'opb-convocatoria-postulante-opinion', params: { id: data.item.id } })"
          >Postulantes
            <feather-icon
              icon="ArrowRightIcon"
              size="12"
              class="align-middle text-white"
            />
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BSpinner,
  BBadge, BDropdown, BDropdownItem, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import opbConvocatoriasStoreModule from './opb_convocatoriasStoreModule'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    // BMedia,
    // BAvatar,
    // BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    vSelect,

    // externs
    // ConvocatoriaModalAddNew,
    // ComunicadosModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

      idConvocatoria: 0,
      btnActivo: 'todos',
      textHistorial: 'Historial',
      botones: [
        { nombre: 'Todos', codigo: 'todos', icon: 'AlignJustifyIcon' },
        { nombre: 'Vigentes', codigo: 'vigente', icon: 'SmileIcon' },
        { nombre: 'En curso', codigo: 'en curso', icon: 'ClockIcon' },
      ],
      filtroEstado: 'todos',
    }
  },
  methods: {
    actualizarTabla() {
      this.filter = null
      this.$refs.refOpbTablaConvocatorias.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('opb-convocatoria-module/getConvocatorias', { estado: this.filtroEstado, queryParams: ctx })
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    filtrarConvocatorias(codigo) {
      this.filtroEstado = codigo
      this.actualizarTabla()
      this.btnActivo = codigo
      if (codigo === 'concluido') {
        this.textHistorial = 'Concluidos'
      }
      if (codigo === 'cancelado') {
        this.textHistorial = 'Cancelados'
      }
      if (codigo !== 'concluido' && codigo !== 'cancelado') {
        this.textHistorial = 'Historial'
      }
    },

  },
  setup() {
    const OPB_CONVOCATORIA_APP_STORE_MODULE_NAME = 'opb-convocatoria-module'

    // Register module
    if (!store.hasModule(OPB_CONVOCATORIA_APP_STORE_MODULE_NAME)) store.registerModule(OPB_CONVOCATORIA_APP_STORE_MODULE_NAME, opbConvocatoriasStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OPB_CONVOCATORIA_APP_STORE_MODULE_NAME)) store.unregisterModule(OPB_CONVOCATORIA_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: 'Comedor', value: 'Comedor' },
      { label: 'Residencia', value: 'Residencia' },
      { label: 'Bolsa de trabajo', value: 'Bolsa de trabajo' },
    ]

    const tableColumns = [
      { key: 'nombre', label: 'nombre', sortable: true },
      { key: 'estado', label: 'estado', sortable: true },
      { key: 'servicios', label: 'servicios', sortable: true },
      { key: 'inscripcion', label: 'Inscripción (inicio-fin)', sortable: true },
      { key: 'comunicados', label: 'comunicados', sortable: true },
      { key: 'accion', label: 'acción', sortable: true },
    ]

    const resolveEstadoVariant = status => {
      if (status === 'borrador') return 'secondary'
      if (status === 'vigente') return 'success'
      if (status === 'en curso') return 'warning'
      if (status === 'concluido') return 'danger'
      if (status === 'cancelado') return 'danger'
      return 'primary'
    }
    const resolveServicioVariant = value => {
      if (value === 'BECA-COMEDOR') return 'primary'
      if (value === 'BECA-RESIDENCIA') return 'success'
      if (value === 'BECA-BOLSA') return 'info'
      return 'primary'
    }
    const resolveServicioIcon = value => {
      if (value === 'BECA-COMEDOR') return 'CoffeeIcon'
      if (value === 'BECA-RESIDENCIA') return 'HomeIcon'
      if (value === 'BECA-BOLSA') return 'BriefcaseIcon'

      return 'UserIcon'
    }

    return {
      opbConvocatoriasStoreModule,
      tableColumns,
      avatarText,
      resolveServicioVariant,
      resolveServicioIcon,
      resolveEstadoVariant,
      roleOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
